import React from 'react';
import Styles from './styles.js';
import { Button, Container, Content, Dropdown, FlexboxGrid, Header, Nav, Navbar } from 'rsuite';
import { Col, Grid, Icon, Row } from 'rsuite';
import Footer from 'components/Footer';
import { Link } from 'utils/with-i18next';
import PropTypes from 'prop-types';
import SlackFeedback from 'components/SlackFeedback';
import AvatarComponent from 'components/Avatar';
import { isEmpty } from 'lodash';
import ChatbotHide from 'components/ChatbotHide';
import detectWindowScreenSize from '../../utils/detect-windows-size';
import { getDefaultRedirectPage } from '../../utils/getDefaultRedirectPage';
import Image from 'next/image.js';

function GenericLayout({ children, currentUser, handleLogout }) {
  const [width] = detectWindowScreenSize();
  const NavbarStyles = {
    padding: 18,
    fontSize: 16,
    background: '#f8f8f8',
    whiteSpace: 'nowrap',
  };

  return (
    <>
      <ChatbotHide show={false} />
      <main>
        <Grid fluid>
          <Row className="generic-container">
            <Col md={24}>
              <Container style={{ minHeight: '100vh' }}>
                <Header>
                  <Navbar style={NavbarStyles}>
                    <Navbar.Header>
                      <Link href={'/'} passHref>
                        <Image
                          className="cursor-pointer h-1/2 mx-0 sm:mx-[30px]"
                          src="/static/images/BigLogo.png"
                          alt="RealSage Logo"
                          height={30}
                          width={30}
                        />
                      </Link>
                    </Navbar.Header>
                    {!isEmpty(currentUser?.email) && (
                      <Navbar.Body>
                        <Nav pullRight>
                          <Dropdown
                            placement="bottomEnd"
                            renderTitle={() => {
                              return (
                                currentUser?.displayName && (
                                  <AvatarComponent
                                    style={{ margin: '3px', backgroundColor: '#fff' }}
                                    name={currentUser?.displayName}
                                  />
                                )
                              );
                            }}>
                            <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
                              <p>Signed in as</p>
                              <strong>{currentUser?.displayName}</strong>
                            </Dropdown.Item>
                            <Dropdown.Item divider />
                            <Dropdown.Item onSelect={handleLogout}>Sign Out</Dropdown.Item>
                          </Dropdown>
                        </Nav>

                        <Nav pullRight style={{ zIndex: 100 }}>
                          <Link href={getDefaultRedirectPage(currentUser)} passHref>
                            <a
                              className="go-to-dashboard"
                              style={{
                                padding: width >= 500 ? '30px' : '15px',
                                lineHeight: '40px',
                                textDecoration: 'none',
                              }}>
                              <Icon icon="angle-double-left go-to-dashboard"></Icon>{' '}
                              {width >= 500 ? 'Go to Dashboard' : 'Dashboard'}
                            </a>
                          </Link>
                        </Nav>
                      </Navbar.Body>
                    )}
                    {isEmpty(currentUser?.email) && (
                      <Navbar.Body>
                        <Nav pullRight>
                          <Button appearance="link" href="/login">
                            Log in
                          </Button>
                        </Nav>
                      </Navbar.Body>
                    )}
                  </Navbar>
                </Header>
                <Content className="generic-content">
                  <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={18}>
                      <Content>{children}</Content>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </Content>
                <Footer />
              </Container>
            </Col>
          </Row>
        </Grid>
        <SlackFeedback user={currentUser}></SlackFeedback>
      </main>
      <Styles />
    </>
  );
}

GenericLayout.propTypes = {
  children: PropTypes.node,
};

export default GenericLayout;
