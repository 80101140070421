import { RotateCcw, SlidersVertical } from 'lucide-react';

//TODO CONNOR - Revert after Demo when Tableau is up and running
// export const dashboardCategories = [
//   {
//     pageId: 'executiveSummary',
//     title: 'Executive Summary',
//     hide: false,
//     dummy: false,
//   },
//   {
//     pageId: 'occupancyAndLeasing',
//     title: 'Occupancy and Leasing',
//     hide: false,
//     dummy: false,
//   },
//   {
//     pageId: 'marketingActivity',
//     title: 'Marketing Activity',
//     hide: false,
//     dummy: false,
//   },
//   {
//     pageId: 'workOrders',
//     title: 'Work Orders',
//     hide: false,
//     dummy: false,
//   },
//   {
//     pageId: 'financialPerformance',
//     title: 'Financial Performance',
//     hide: false,
//     dummy: false,
//   },
//   {
//     pageId: 'residentActivity',
//     title: 'Resident Activity',
//     hide: false,
//     dummy: false,
//   }
// ];

export const dashboardCategories = [
  {
    pageId: 'unitTurnData',
    title: 'Unit Turn Data',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'occupancyAndLeasing',
    title: 'Occupancy and Leasing',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'budgetedVsActual',
    title: 'Budgeted vs. Actual',
    hide: false,
    dummy: false,
  },
];

export const toolLinks = [
  {
    pageId: 'bookings',
    title: 'Convert',
    icon: <RotateCcw height={22} width={22} />,
    link: '/dashboard/bookings',
    hide: false,
    dummy: false,
  },
  // {
  //   pageId: 'tickets',
  //   title: 'Engage',
  //   icon: <RefreshCcw height={22} width={22} />,
  //   link: '/dashboard/tickets',
  //   hide: false,
  //   dummy: false,
  // },
  // {
  //   pageId: 'income-management',
  //   title: 'Income Management',
  //   icon: <Coins height={22} width={22} />,
  //   link: '/dashboard/income-management',
  //   hide: false,
  //   dummy: false,
  // },
  {
    pageId: 'price',
    title: 'Revenue Management',
    icon: <SlidersVertical height={22} width={22} />,
    link: '/dashboard/price',
    hide: false,
    dummy: false,
  },
];

export const insightCategories = [
  {
    insightName: 'leasing',
    title: 'Leasing',
  },
  {
    insightName: 'management',
    title: 'Management',
  },
  {
    insightName: 'management_data',
    title: 'Management Data',
  },
  {
    insightName: 'marketing',
    title: 'Marketing',
  },
  {
    insightName: 'marketing_leasing_data',
    title: 'Marketing & Leasing Data',
  },
  {
    insightName: 'revenue_management',
    title: 'Revenue Management',
  },
  {
    insightName: 'tenants',
    title: 'Tenants',
  },
  {
    insightName: 'unit_turn_data',
    title: 'Unit Turn Data',
  },
];
