import React, { useState } from 'react';
import Link from 'next/link';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuLabel,
} from '@/components/ui/dropdown-menu';
import { ChevronDown, Plus, LogOut } from 'react-feather';
import AvatarComponent from 'components/Avatar';
import SideNavMobile from '../SideNavMobile';
import useCloseOnRouteChange from 'utils/hooks/useCloseOnRouteChange';
import { useUser } from 'contexts/UserContext';
import LeniQEntry from 'components/LeniQ/LeniQEntry';

export default function TopNav({ mobileMenu, handleLogout }) {
  const { user: currentUser } = useUser();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  useCloseOnRouteChange(setDropdownOpen);
  return (
    <div
      className={`flex row-start-1 items-center gap-5 justify-end bg-white-50 border-[1px] border-gray-100 pr-12 rounded-2xl shadow-lg h-[70px] ${
        mobileMenu && 'pl-6'
      } mb-3`}>
      {mobileMenu && <SideNavMobile />}
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger className="[&[data-state=open]>div>div>svg]:rotate-180 focus:outline-none active:outline-none focus:ring-none outline:ring-none">
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-3 cursor-pointer">
              <AvatarComponent size="sm" name={currentUser?.displayName} />
              <ChevronDown height={20} width={20} className="transition-all duration-300 rotator" />
            </div>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="p-0 -translate-x-[10%] max-w-[250px]">
          <DropdownMenuLabel className="flex gap-3 px-3 items-center">
            <div className="flex flex-col items-end justify-center">
              <span className="text-gray-800 text-sm font-medium">{`${currentUser.firstName} ${currentUser.lastName}`}</span>
              <span className="text-gray-400 text-xs font-medium">{currentUser.email}</span>
            </div>
            <AvatarComponent size="sm" name={currentUser?.displayName} />
          </DropdownMenuLabel>
          <Link href="/dashboard/settings">
            <DropdownMenuItem className="cursor-pointer border-t-[1px] border-gray-100 px-8">
              Account Settings
            </DropdownMenuItem>
          </Link>
          <Link href="/dashboard/reports">
            <DropdownMenuItem className="cursor-pointer flex items-center gap-2 border-y-[1px] border-gray-100 px-8">
              <Plus height={15} width={15} />
              <span className="text-sm">Upload a file</span>
            </DropdownMenuItem>
          </Link>
          <DropdownMenuItem className="cursor-pointer flex items-center gap-2 px-8" onClick={handleLogout}>
            <LogOut height={15} width={15} />
            <span className="text-sm">Log out</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <LeniQEntry />
    </div>
  );
}
