import { useRouter } from 'next/router.js';
import { Link } from 'utils/with-i18next';
import PropTypes from 'prop-types';
import ChatbotHide from 'components/ChatbotHide';
import BackgroundWrapper from 'components/BackgroundWrapper';
import Image from 'next/image';
import useMediaQuery from 'utils/hooks/useMediaQuery';

function AuthLayout({ children }) {
  const router = useRouter();
  const { pathname } = router;
  const isLoginPage = pathname === '/login';
  const isMobile = useMediaQuery('(max-width: 639px)');
  const isTablet = useMediaQuery('(max-width: 1000px)');
  return (
    <>
      <ChatbotHide show={false} debug={true} />
      <main className={`${isLoginPage ? 'bg-login-gradient' : 'bg-white-200'} relative overflow-hidden`}>
        <BackgroundWrapper>
          {isLoginPage ? (
            <div className="flex justify-center items-start w-full sm:w-[90%] mx-auto">
              <div className="flex flex-col items-center justify-center lg:grid lg:grid-cols-2 h-screen w-full">
                <div className="flex flex-col-reverse lg:gap-20 lg:flex-col justify-start sm:justify-end self-center justify-self-center items-center lg:justify-evenly lg:items-start lg:h-[50%] sm:mt-0">
                  <h1 className="display-lg lg:display-2xl flex flex-col items-center lg:items-start mt-12 sm:mt-0 mb-8 lg:mb-0">
                    <span className="text-white-50 text-center lg:text-left">Real estate insights,</span>
                    <span className="text-accent-400 text-center lg:text-left">redefined.</span>
                  </h1>
                  <Link href="/login">
                    <Image
                      src="/static/images/leni-logo.svg"
                      height={65}
                      width={125}
                      alt="RealSage logo"
                      className="cursor-pointer"
                    />
                  </Link>
                </div>
                <div className="self-center justify-self-center w-full sm:w-auto">{children}</div>
              </div>
            </div>
          ) : (
            <div>{children}</div>
          )}
        </BackgroundWrapper>
      </main>
    </>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.node,
};

export default AuthLayout;
