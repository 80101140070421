import { useUser } from 'contexts/UserContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GptApi } from 'services/api/gpt';
import { TableAccessContext } from 'contexts/TableAccessContext.tsx';
import Typewriter from 'typewriter-effect';
import { ProcessGptResponse, processGptResponse } from 'utils/LeniQ/processGptResponse';
import { Copy, ThumbsUp } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import LoadingSpinnerAlt from '@/components/ui/spinner-alternate';
import { getScreenCapture } from 'utils/LeniQ/getScreenCapture';
import { GptRunModelsData } from 'src/types/Services/GPT/gpt';
import { AxiosResponse } from 'axios';
import { User } from 'src/types/User/user';
import LeniQFeedbackModal from 'components/Modals/LeniQFeedbackModal';
import { ScrollArea } from '@/components/ui/scroll-area';

interface LeniQAnswerProps {
  question: string;
}

const LeniQAnswer: React.FC<LeniQAnswerProps> = ({ question }) => {
  const { user: currentUser } = useUser();
  const tableAccessContext = useContext(TableAccessContext);
  const [loading, setLoading] = useState(true);
  const [questionAnswer, setQuestionAnswer] = useState<ProcessGptResponse | null>(null);
  const [hasRated, setHasRated] = useState(false);

  const { toast } = useToast();

  const copyToClipboard = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      toast({
        description: 'Copied to clipboard!',
        variant: 'success',
        duration: 2000,
      });
    } catch (error) {
      toast({
        description: 'Oops! Something went wrong.',
        variant: 'error',
        duration: 2000,
      });
    }
  };

  const isQuestionAboutExecutiveSummary = (question: string) => {
    return question.toLowerCase().includes('executive summary');
  };

  const askGptQuestion = useCallback(
    async (question: string) => {
      if (!currentUser) return;
      setLoading(true);
      try {
        const showPrewrittenResponse = (summary: string) => {
          setTimeout(() => {
            setQuestionAnswer({
              answer: [{ count: 1 }],
              questionId: '10293019238019230912',
              summary,
            });
            setLoading(false);
          }, 4000); // Wait for 4 seconds to simulate loading
        };

        if (isQuestionAboutExecutiveSummary(question)) {
          showPrewrittenResponse(`
            <strong>Troy Boston</strong> is situated in Boston and has a revenue of <strong>$11,296,258. After accounting for expenses,
            which total $4,828,626</strong>, the gross margin stands strong at <strong>$6,467,632</strong>. The building demonstrates a <strong>good
            occupancy rate of 98.89%</strong> when compared to the neighborhood occupancy.
            <br />
            <br />
            Meanwhile, <strong>Parkway Apartments</strong> is another building in Boston. Generating a <strong>revenue of $12,285,020</strong> and incurring <strong>expenses of $6,043,149</strong>,
            the apartments achieve a NOI of <strong>$6,241,871</strong>. The occupancy rate is slightly below that of Troy Boston,
            settling at <strong>98.70%</strong>. 
            <br />
            <br />
            In Toronto, <strong>Bridge Condos</strong> has made its mark with the highest occupancy rate among
            the listed properties, a remarkable <strong>99.14%</strong>. Financially, the condos have garnered a revenue of
            $4,539,450, and after deducting expenses of <strong>$2,298,619, the NOI is ascertained at $2,240,831</strong>. 
            <br />
            <br />
            Lastly, <strong>Liberty Towers</strong>, also located in Toronto, is pulling in a revenue of <strong>$6,821,198</strong>. The expenses for this
            property amount to <strong>$3,050,755</strong>, resulting in a net operating income of <strong>$3,770,443</strong>. The occupancy rate
            is competitive, noted at <strong>98.87%</strong>. 
            <br />
            <br />
            In summary, while Troy Boston holds the lead in terms of NOI, Bridge
            Condos in Toronto stands out with the highest occupancy rate.
          `);
        } else if (question.toLowerCase().includes('how is the marketing performance this quarter')) {
          showPrewrittenResponse(`
            When evaluating lead generation, <strong>Zumper tops the list with 647 leads</strong>,
            followed closely by <strong>Facebook with 631 leads</strong>. Our <strong>website generated the fewest leads, totaling 221</strong>.
            <br/>
            Nevertheless, when considering conversion rates, the website has <strong>11.95%</strong>, whereas Facebook's rate stands at <strong>4.25%</strong>.
            Zumper <strong>recorded a 0% conversion rate</strong>.
            <br />
            <br />
            Given the marketing investment for each channel, our website emerges as the top performer, even with its lower lead count.
          `);
        } else if (question.toLowerCase().includes('provide an update on troy boston occupancy')) {
          showPrewrittenResponse(`
            Troy Boston currently holds a <strong>99.0%</strong> occupancy rate, with projections indicating a decline to <strong>94.2% in the next 90 days</strong>. This is a shortfall from our target of <strong>97.3%</strong>, resulting in a <strong>-3.10% difference</strong>.
            <br/>
            <br/>
            There is a <strong>decrease in lease renewals</strong>. In the last month, anticipated renewal rates have slipped by <strong>5.0% to 43.1%</strong>. Feedback from move-out reasons points to pricing as a central concern. A minor price reduction might enhance renewal rates, bringing us closer to our desired occupancy objectives.
          `);
        } else if (question.toLowerCase().includes('identify revenue improvement opportunities')) {
          showPrewrittenResponse(`
            Currently, your advertised rent in Toronto is priced <strong>10% lower</strong> than the recent average.
            Impressively, your portfolio exhibits a <strong>23% renewal growth</strong>, coupled with a <strong>2.10% average</strong> conversion rate.
            By strategically raising the rents while sustaining your present renewal rate, there's a potential to augment your earnings by an extra <strong>$17,784</strong>.
            <br/>
            <br/> 
            Consider increasing the rent by <strong>$25 for units</strong> that are presently available.
            Furthermore, you have an edge in the Annex neighborhood, where your vacancy rate is notably lower than the local market standard.
            Capitalizing on this position can bolster your annual revenues by an additional <strong>$3,000</strong>.
          `);
        } else {
          const userPayload = {
            ...currentUser,
            tables: tableAccessContext?.state?.data?.tables,
          };
          const res: AxiosResponse<GptRunModelsData> = await GptApi.runModels(
            question,
            userPayload,
            isQuestionAboutExecutiveSummary(question) ? await getScreenCapture() : null
          );
          const answerInfo = processGptResponse(res);

          if (!answerInfo) {
            throw 'Something went wrong!';
          }
          setQuestionAnswer(answerInfo);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [currentUser, tableAccessContext?.state?.data?.tables]
  );

  useEffect(() => {
    askGptQuestion(question);
    setHasRated(false);
  }, [question, askGptQuestion]);

  const handlePositiveFeedback = async (
    questionId: string,
    user: User | null,
    setHasRated: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!user) return;
    try {
      await GptApi.giveFeedback({ success: true, questionId, user });
      toast({
        variant: 'success',
        description: 'Your feedback has been successfully submitted',
        duration: 3000,
      });
      setHasRated(true);
    } catch (error) {
      console.log(error);
      toast({
        variant: 'error',
        description: 'Something went wrong when giving your feedback - Please try again later',
        duration: 3000,
      });
      setHasRated(false);
    }
  };

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <LoadingSpinnerAlt />
      </div>
    );
  }

  return (
    <>
      {loading && (
        <div className="h-full flex items-center justify-center">
          <LoadingSpinnerAlt />
        </div>
      )}
      <div className="flex h-full flex-col items-start mt-6 text-md">
        {questionAnswer && questionAnswer.summary && (
          <ScrollArea className="max-h-[500px]">
            <Typewriter
              options={{ delay: 10, cursor: '' }}
              onInit={typewriter => {
                typewriter.typeString(questionAnswer.summary as string).start();
              }}
            />
          </ScrollArea>
        )}
        <div className="flex items-center gap-3 mt-2">
          <ThumbsUp
            height={14}
            width={14}
            className={`cursor-pointer ${hasRated ? 'pointer-events-none text-gray-100' : 'text-gray-400'}`}
            onClick={() => handlePositiveFeedback(questionAnswer?.questionId as string, currentUser, setHasRated)}
          />
          <LeniQFeedbackModal
            questionId={questionAnswer?.questionId as string}
            setHasRated={setHasRated}
            hasRated={hasRated}
          />
          <Copy
            height={14}
            width={14}
            className="text-gray-400 cursor-pointer"
            onClick={() => copyToClipboard(questionAnswer?.summary as string)}
          />
        </div>
      </div>
    </>
  );
};

export default LeniQAnswer;
