import { useState } from 'react';
import Image from 'next/image';
import { Sheet, SheetTrigger, SheetContent, SheetFooter } from '@/components/ui/sheet.tsx';
import { Menu } from 'react-feather';
import SideNav from '../SideNav';
import SideNavFooter from '../SideNavFooter';
import useCloseOnRouteChange from 'utils/hooks/useCloseOnRouteChange';
import { Button } from '@/components/ui/button.tsx';
import { useRouter } from 'next/router';
export default function SideNavMobile() {
  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();

  useCloseOnRouteChange(setIsOpen);

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <div className="mr-auto flex items-center gap-3 cursor-pointer min-h-[100px] min-w-[100px]">
          <Image src="/static/images/leni-logo-small.png" resi height={30} width={30} alt="Leni logo" />
          <Menu height={25} width={25} className="translate-y-[15%]" />
        </div>
      </SheetTrigger>
      <SheetContent
        side="left"
        overlay={false}
        className="bg-primary-900 shadow-none grid grid-rows-12 px-0 pb-0 text-gray-200">
        <SideNav mobile />
        <Button
          className="row-start-11 translate-y-[25%] self-end ml-6 flex gap-2 items-center rounded-full max-w-[150px] bg-transparent hover:bg-transparent cursor-pointer transition-all duration-300 hove:scale-[1.02]"
          onClick={() => router.push('/dashboard/sage-plus')}>
          <Image src="/static/icons/sage-plus-icon.svg" height={25} width={25} alt="LeniQ" />
          <span className="text-primary-600 text-sm font-bold translate-y-[5%]">LeniQ</span>
        </Button>
        <SheetFooter className="row-start-12">
          <SideNavFooter mobile />
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
