import React from 'react';
import Link from 'next/link';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { FileText, Plug } from 'lucide-react';

export default function SideNavFooter({ opened }) {
  return (
    <TooltipProvider>
      <div
        className={`h-[100px] sm:h-auto col-start-1 ${opened ? 'row-start-12' : 'row-start-11'} w-full relative ${
          opened ? 'border-t-[1px] border-gray-500' : 'mb-12'
        }`}>
        <div className={`flex ${!opened ? 'flex-col gap-4 mt-12' : 'gap-1'} justify-evenly items-center h-[100%]`}>
          <Tooltip>
            <Link href="/dashboard/integrations" passHref>
              <TooltipTrigger asChild>
                <span className="cursor-pointer">{opened ? 'Integrations' : <Plug height={22} width={22} />}</span>
              </TooltipTrigger>
            </Link>
            <TooltipContent side="right" className="bg-primary-800 border-none text-white-50">
              Integrations
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <Link
              href="https://realsage.notion.site/960a0c48ab48455f8e024d324dceaf88?v=0562f53322224c55beda0018a621aff0"
              passHref>
              <TooltipTrigger asChild>
                <a
                  target="_blank"
                  className="text-gray-200 hover:text-gray-200 active:text-gray-200 focus:text-gray-200 hover:no-underline focus:no-underline">
                  <div className="flex cursor-pointer gap-1 font-bold items-center">
                    {opened ? <span>Guides</span> : null}
                    <FileText height={22} width={22} />
                  </div>
                </a>
              </TooltipTrigger>
            </Link>
            <TooltipContent side="right" className="bg-primary-800 border-none text-white-50">
              <p>Guides</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
    </TooltipProvider>
  );
}
