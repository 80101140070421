import CheckRoundIcon from '@rsuite/icons/CheckRound';

const ServicesStatusComponent = () => {
  return (
    <>
      <div className=" flex justify-center flex-col text-center max-w-2xl m-auto">
        <div className="  flex justify-center m-10">
          <CheckRoundIcon className="text-[200px] text-green-600" />
        </div>
        <p className="text-3xl  font-bold my-4">The Realsage app is up and running</p>
        <p className="my-4 text-xl ">All systems are operational.</p>
      </div>
    </>
  );
};

export default ServicesStatusComponent;
