import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export default function BackgroundWrapper({ children }) {
  const router = useRouter();
  const { pathname } = router;
  const [imgPath, setImgPath] = useState('');

  useEffect(() => {
    if (pathname.includes('login')) {
      setImgPath('static/images/leni-logo.svg');
    } else {
      setImgPath('static/images/leni-logo-black.svg');
    }
  }, [pathname]);

  return (
    <div className="relative overflow-hidden">
      <div
        className="absolute inset-0 bg-90p md:bg-contain bg-no-repeat bg-bottom sm:bg-left opacity-30 mix-blend-overlay"
        style={{
          backgroundImage: `url('${imgPath}')`,
        }}></div>
      <div className="relative z-10 h-screen w-full">{children}</div>
    </div>
  );
}
