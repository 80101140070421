import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from '@/components/ui/accordion.tsx';
import { ChevronDown } from 'react-feather';
import { useRouter } from 'next/router';
import { dashboardCategories, toolLinks } from 'utils/Navigation/constants.tsx';
import { Building, LineChart, Menu } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { HoverCard } from '@/components/ui/hover-card';
import { HoverCardTrigger, HoverCardContent } from '@/components/ui/hover-card';
import { useUser } from 'contexts/UserContext';

export default function SideNav({ mobile, opened, setOpened }) {
  const { user: currentUser } = useUser();
  const router = useRouter();
  const pathname = router.pathname;
  const { query } = router;
  const [analyticsOpen, setAnalyticsOpen] = useState(Object.keys(query).includes('page'));

  const handleAnalyticsToggle = () => {
    setAnalyticsOpen(prev => {
      return prev ? false : true;
    });
  };

  // Expanded wider sidebar
  if (opened) {
    return (
      <div className={`flex flex-col items-start gap-5 px-3 ${mobile && 'px-8'}`}>
        <div className="pl-3 flex items-center gap-3">
          <span className="p-2 cursor-pointer hover:bg-primary-700 rounded-full transition-all">
            <Menu height={25} width={25} className="text-white-50" onClick={() => setOpened(false)} />
          </span>
          <span className="hover:cursor-pointer mt-2">
            <Link href="/dashboard/analytics?page=unitTurnData">
              <Image src="/static/images/leni-logo-big.png" height={35} width={120} alt="RealSage logo" />
            </Link>
          </span>
        </div>
        <div className="flex flex-col gap-1 w-[100%]">
          <div className="flex items-center w-[100%]">
            <Accordion type="single" collapsible className="w-[100%] px-4" value={analyticsOpen ? 1 : undefined}>
              <AccordionItem value={1} className="border-none">
                <AccordionTrigger className="py-0 border-none" icon={<LineChart height={15} width={15} />}>
                  <span className="flex items-center text-md" onClick={handleAnalyticsToggle}>
                    Analytics
                    <ChevronDown className="h-4 w-4 ml-3 shrink-0 transition-transform duration-200 rotator" />
                  </span>
                  <Image src="/static/icons/line-graph.svg" height={15} width={15} alt="" />
                </AccordionTrigger>
                {dashboardCategories.map(category => {
                  // Check if we are on a specific sub-page of analytics dashboard
                  const isPage = query?.page === category.pageId;
                  return (
                    <AccordionContent key={category.pageId} className="pb-0">
                      <Link href={`/dashboard/analytics?page=${category.pageId}`} passHref>
                        <span
                          className={`px-4 py-[6px] mb-[1px] flex justify-between rounded-full hover:bg-primary-800 ${
                            isPage ? 'bg-primary-800' : 'bg-primary-900'
                          } active:bg-primary-800 cursor-pointer active:ring-0 focus:ring-0 active:ring-inset-0 focus:ring-inset-0 active:border-none focus:border-none transition-all`}>
                          {category.title}
                        </span>
                      </Link>
                    </AccordionContent>
                  );
                })}
              </AccordionItem>
            </Accordion>
          </div>
          <Link href="/dashboard/properties">
            <div
              className={`px-4 py-[6px] flex items-center justify-between rounded-full hover:bg-primary-800 active:bg-primary-800 ${
                '/dashboard/properties' === pathname ? 'bg-primary-800' : 'bg-primary-900'
              } cursor-pointer active:ring-0 focus:ring-0 active:ring-inset-0 focus:ring-inset-0 active:border-none focus:border-none transition-all`}>
              <span className="text-gray-200 text-md">Properties</span>
              <Building height={15} width={15} />
            </div>
          </Link>
          {toolLinks
            .filter(i => !i.hide && !i.dummy && (currentUser?.views ? currentUser?.views.includes(i.pageId) : true))
            .map(navItem => (
              <Link key={`${navItem.link} ${navItem.pageId}`} href={navItem.link}>
                <div
                  className={`px-4 py-[6px] flex justify-between items-center rounded-full hover:bg-primary-800 active:bg-primary-800 ${
                    navItem.link === pathname && 'bg-primary-800 hover:bg-primary-800'
                  } cursor-pointer active:ring-0 focus:ring-0 active:ring-inset-0 focus:ring-inset-0 active:border-none focus:border-none transition-all`}>
                  <span className="text-gray-200 text-md">{navItem.title}</span>
                  {navItem.icon}
                </div>
              </Link>
            ))}
        </div>
      </div>
    );
  }

  // Collapsed narrow sidebar
  return (
    <TooltipProvider>
      <div className="row-start-1 flex flex-col items-center">
        <div className="flex flex-col items-center">
          <span className="p-2 cursor-pointer hover:bg-primary-700 rounded-full transition-all mb-4">
            <Menu height={25} width={25} className="text-white-50" onClick={() => setOpened(true)} />
          </span>
          <span className="hover:cursor-pointer">
            <Link href="/dashboard/analytics?page=unitTurnData">
              <Image src="/static/images/leni-logo-small.png" height={35} width={35} alt="RealSage logo" />
            </Link>
          </span>
        </div>
        <div className="flex flex-col mt-5 gap-2">
          <HoverCard>
            <Link href="/dashboard/analytics?page=executiveSummary">
              <HoverCardTrigger asChild>
                <span
                  className={`p-2 flex items-center justify-center rounded-full ${
                    pathname === '/dashboard/analytics' && 'bg-primary-800'
                  } cursor-pointer hover:bg-primary-800 transition-all`}>
                  <LineChart height={22} width={22} />
                </span>
              </HoverCardTrigger>
            </Link>
            <HoverCardContent
              side="right"
              className="flex flex-col bg-primary-800 text-white-50 border-none p-0 rounded-lg">
              {dashboardCategories.map((category, index) => (
                <Link key={`${category.pageId}-hover-card`} href={`/dashboard/analytics?page=${category.pageId}`}>
                  <span
                    className={`${
                      query?.page === category.pageId ? 'bg-primary-700' : 'bg-primary-800'
                    } py-2 px-3 cursor-pointer hover:bg-primary-700 transition-all ${
                      index === 0 ? 'rounded-t-md' : ''
                    } ${index === dashboardCategories.length - 1 ? 'rounded-b-md' : ''}`}>
                    {category.title}
                  </span>
                </Link>
              ))}
            </HoverCardContent>
          </HoverCard>
          <Tooltip>
            <Link href="/dashboard/properties">
              <TooltipTrigger asChild>
                <span
                  className={`p-2 flex items-center justify-center rounded-full ${
                    pathname === '/dashboard/properties' && 'bg-primary-800'
                  } cursor-pointer hover:bg-primary-800 transition-all`}>
                  <Building height={22} width={22} />
                </span>
              </TooltipTrigger>
            </Link>
            <TooltipContent side="right" className="bg-primary-800 border-none text-white-50">
              <p>Properties</p>
            </TooltipContent>
          </Tooltip>
          {toolLinks
            .filter(i => !i.hide && !i.dummy && (currentUser?.views ? currentUser?.views.includes(i.pageId) : true))
            .map(navItem => (
              <Tooltip key={`${navItem.link} ${navItem.pageId}`}>
                <Link href={navItem.link}>
                  <TooltipTrigger asChild>
                    <span
                      className={`p-2 flex items-center justify-center rounded-full ${
                        pathname === navItem.link && 'bg-primary-800'
                      } cursor-pointer hover:bg-primary-800 transition-all`}>
                      {navItem.icon}
                    </span>
                  </TooltipTrigger>
                </Link>
                <TooltipContent side="right" className="bg-primary-800 border-none text-white-50">
                  <p>{navItem.title}</p>
                </TooltipContent>
              </Tooltip>
            ))}
        </div>
      </div>
    </TooltipProvider>
  );
}
