import React, { useEffect, useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import InsightHeader from 'components/Insights/InsightHeader';
import InsightBody from 'components/Insights/InsightBody';
import { Button } from '@/components/ui/button';
import useInsightContext from 'utils/hooks/useInsightContext';
import { Insight } from 'src/types/Insights/insight';
import { useToast } from '@/components/ui/use-toast';
import InsightMonitoringBody from 'components/Insights/InsightMonitoringBody';

const InsightsContainer: React.FC = () => {
  const [insight, setInsight] = useState<Insight | null>(null);
  const { toast } = useToast();
  const {
    state: { activeInsight, allInsights, activeTab },
    dispatch: insightDispatch,
  } = useInsightContext();

  const memoizedActiveInsight = useMemo(() => activeInsight, [activeInsight]);
  const memoizedAllInsights = useMemo(() => allInsights, [allInsights]);

  useEffect(() => {
    if (memoizedActiveInsight) {
      const foundInsight = memoizedAllInsights.find(insight => insight.id === memoizedActiveInsight.id);
      setInsight(foundInsight || null);
    }
  }, [memoizedActiveInsight, memoizedAllInsights]);

  const handleToggleMonitoring = () => {
    if (!memoizedActiveInsight || !insight) return;
    const setToMonitor = !insight.monitoring;
    const updatedInsights = memoizedAllInsights.map(insight => {
      if (insight.id !== memoizedActiveInsight.id) return insight;
      return { ...insight, monitoring: !insight.monitoring };
    });

    insightDispatch({
      type: 'SET_ALL_INSIGHTS',
      payload: updatedInsights,
    });

    toast({
      variant: 'success',
      description: setToMonitor
        ? 'This insight is now being monitored and you will receive updates for any relevant changes'
        : 'This insight will no longer be monitored',
    });
  };

  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={{ duration: 0.3, ease: 'linear' }}
      className="flex flex-col justify bg-white-50 border-[1px] border-gray-100 rounded-3xl shadow-lg w-[350px] row-start-1 row-end-13 col-start-3 col-end-4 p-4">
      <InsightHeader />
      <AnimatePresence mode="wait">
        {activeTab === 'history' && <InsightMonitoringBody />}
        {activeTab === 'insight' && (
          <motion.div
            key="insight"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ duration: 0.3, ease: 'linear' }}
            className="h-full flex flex-col">
            <InsightBody />
            <div className="mt-auto flex justify-center">
              {!insight?.monitoring ? (
                <Button className="font-bold basis-4/5" onClick={handleToggleMonitoring}>
                  Accept Action
                </Button>
              ) : (
                <Button
                  variant="outline"
                  className="font-bold border-primary-900 basis-4/5"
                  onClick={handleToggleMonitoring}>
                  Stop Monitoring
                </Button>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default InsightsContainer;
