import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { UserApi } from 'services/api/user';
import { useRouter } from 'next/router';
import SideNav from 'components/Navigation/SideNav/index.js';
import SideNavFooter from 'components/Navigation/SideNavFooter/index.js';
import TopNav from 'components/Navigation/TopNav/index.js';
import useMediaQuery from 'utils/hooks/useMediaQuery.js';
import { ScrollArea } from '@/components/ui/scroll-area.tsx';
import InsightsContainer from 'containers/Insights/index.tsx';
import { motion, AnimatePresence } from 'framer-motion';
import useInsightContext from 'utils/hooks/useInsightContext';
import useLeniQContext from 'utils/hooks/useLeniQContext';
import LeniQContainer from 'containers/LeniQ';
import useTableauDashboardContext from 'utils/hooks/useTableauDashboardContext';

function DashboardLayout({ children, handleLogout }) {
  const [notifications, setNotifications] = useState([]);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const router = useRouter();
  const pathname = useMemo(() => router.pathname, [router]);
  const {
    state: { insightsDrawerOpen },
    dispatch: insightDispatch,
  } = useInsightContext();

  const { tableauDashboardState, tableauDashboardDispatch } = useTableauDashboardContext();

  const {
    state: { leniQDrawerOpen },
    dispatch: leniQDispatch,
  } = useLeniQContext();

  useEffect(() => {
    const fetchNotifications = async () => {
      const { data } = await UserApi.getNotifications();
      let banners = [];
      let popups = [];
      const allNotifications = data;
      const viewNotifications = allNotifications.filter(x => !x.hide);
      viewNotifications.forEach(notification => {
        if (notification.campaign_type === 'Banner') {
          banners.push(notification);
        }
        if (notification.campaign_type === 'Popup') {
          popups.push(notification);
        }
      });
      setNotifications(allNotifications);
    };
    fetchNotifications();
  }, [router]);

  useEffect(() => {
    if (leniQDrawerOpen && insightsDrawerOpen) {
      insightDispatch({
        type: 'SET_INSIGHTS_DRAWER_OPEN',
        payload: false,
      });
    }
  }, [leniQDrawerOpen]);

  useEffect(() => {
    if (leniQDrawerOpen && insightsDrawerOpen) {
      leniQDispatch({
        type: 'SET_LENIQ_DRAWER_OPEN',
        payload: false,
      });
    }
  }, [insightsDrawerOpen]);

  const isLarge = useMediaQuery('(min-width: 1024px)');

  useEffect(() => {
    if (leniQDrawerOpen) {
      leniQDispatch({
        type: 'SET_LENIQ_DRAWER_OPEN',
      });
    } else if (insightsDrawerOpen) {
      insightDispatch({
        type: 'SET_INSIGHTS_DRAWER_OPEN',
        payload: false,
      });
    }
  }, [pathname]);

  return (
    <main
      className={`bg-gray-50 grid ${
        leniQDrawerOpen || insightsDrawerOpen ? (isLarge ? 'grid-cols-3' : 'grid-cols-2') : 'grid-cols-2'
      } ${
        !sideNavOpen ? 'sm:grid-cols-[80px_1fr]' : 'sm:grid-cols-[minmax(150px,275px)_1fr]'
      } grid-rows-12 relative p-3 gap-x-3`}>
      {isLarge && (
        <div
          className={`shadow-lg rounded-2xl row-start-1 row-end-13 pt-10 grid grid-rows-12 bg-primary-900 text-gray-200 border-r-[1px] border-gray-100 transition-all duration-300 ${
            sideNavOpen ? 'w-[minmax(150px,275px)]' : 'w-[80px]'
          }`}>
          <SideNav opened={sideNavOpen} setOpened={setSideNavOpen} />
          <SideNavFooter opened={sideNavOpen} />
        </div>
      )}
      {/* TopNav */}
      <motion.div
        className={`row-start-1 row-end-13 ${
          leniQDrawerOpen || insightsDrawerOpen ? 'col-start-2 col-end-3' : 'col-start-2 col-end-4'
        } ${!isLarge && 'col-start-1 col-end-3'} p-0 grid grid-rows-11 gap-4`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <TopNav mobileMenu={!isLarge} handleLogout={handleLogout} notifications={notifications} />
        <ScrollArea className="bg-white-50 border-[1px] border-t-[0px] border-gray-100 rounded-2xl row-start-2 row-end-12 shadow-xl">
          {children}
        </ScrollArea>
      </motion.div>
      <AnimatePresence>{leniQDrawerOpen && <LeniQContainer />}</AnimatePresence>
      <AnimatePresence>{insightsDrawerOpen && <InsightsContainer />}</AnimatePresence>
    </main>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
  currentUser: PropTypes.object,
  handleLogout: PropTypes.func,
};

export default DashboardLayout;
